import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import NavPrimary from '../../components/layouts/NavPrimary.js'
import SecondaryNav from '../../components/layouts/NavSecondary.js'
import Footer from '../../components/layouts/Footer.js'

import GContentConfirmation from '../../components/ContentGrids/Quote/ConfirmationContentGrid.js'

const PGComponentConfirmation = styled.div`
  height: 100vh;
  background-color: #fff;

  // 🖋
  font-family: 'Open Sans';
  color: #0033a1;
  // 📰
  display: grid;
  grid-template-rows: 0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "progress_indicator"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .ProgressIndicatorStateTwo {
    grid-area: progress_indicator; // 🗺
    align-self: start;
  }
  .ComponentContent {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`
const GComponentContent = styled.div`
    margin: 0 auto;
    max-width: 1360px;
`

const PageGridComponentAddItems = () => {
  return (
    <PGComponentConfirmation>
      <SecondaryNav></SecondaryNav>
      <NavPrimary></NavPrimary>

        <GComponentContent>
          <GContentConfirmation>
            
          </GContentConfirmation>
        </GComponentContent>
      <Footer></Footer>
    </PGComponentConfirmation>
  )
}

export default PageGridComponentAddItems;